import React, {Component} from 'react'
import {ENV} from "../actions/config";
import { connect } from 'react-redux';
import withRouter from "react-router-dom/es/withRouter";
import {withTranslation, Trans} from 'react-i18next';
import Tabs from "./common/Tabs";
import {
    Row,
    Col,
    Card,
    Button
} from 'react-materialize';
import Spinner from "./common/Spinner";
import axios from 'axios';
import Swal from 'sweetalert2';

let pageStyle = {
    backgroundSize:"cover",
    color:"grey",
    height:"100vh"
};
let textStyle = {
    marginBottom: '8px', padding: '8px', color: '#000000',fontSize: '18px',fontWeight: 'bold'
}


class ImportData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
            currentActiveTab: 'ImportTrx',
            selectedFile: null,
            loading: false,
            timelybillsTrx: null
        }
    }

    setActiveTab = (tab, d) => {
        this.setState({
            currentActiveTab: tab.id
        });
    };

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const t=this.props.t;
        if(this.state.selectedFile === null || this.state.selectedFile === undefined) {
            this.getAlertBox('NO_FILE_SELECTED', 'error');
            return;
        }
        const formData = new FormData();
        formData.append('csvFile', this.state.selectedFile);
        await this.handleMintTransactionsUpload(formData, false);
    
      };

    handleMintTransactionsUpload = async (formData, mintOverRide) => {
        try {
            const t=this.props.t;
            this.setState({ loading: true })
            const response = await axios.post(`${ENV.API}api/userdata/uploadMintTransactions/${mintOverRide}`, formData, {
                //const response = await axios.post(`http://localhost:8081/v1/api/userdata/uploadMintTransactions/${mintOverRide}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            this.setState({
                loading: false,
                selectedFile: null,
                timelybillsTrx: null
            });
    
            if (response.data.code === 421) {
                const confirmOverride = await Swal.fire({
                    text: t("MINT_TRXN_ALREADY_IMPORTED"),
                    confirmButtonText: t("Confirm"),
                    showCancelButton: true,
                });
    
                if (confirmOverride.value) {
                    await this.handleMintTransactionsUpload(formData, true);
                }
            } else {
                this.getSuccessAlertBox('FILE_UPLOAD_SUCCESS');      
            }
        } catch (error) {
            this.setState({ loading: false ,selectedFile: null});
            this.getAlertBox('FILE_UPLOAD_ERROR', 'error');
            console.error('Upload error:', error);
        }
    };  

    handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSize = 3 * 1024 * 1024;
        if(selectedFile && selectedFile.size > maxSize) {
            this.getAlertBox('FILE_SIZE_ERROR', 'error');
        }
        this.setState({selectedFile:selectedFile})
    }

    handleTimelyBillsTrxFormSubmit = async (event) => {
        event.preventDefault();
        const t=this.props.t;
        if(this.state.timelybillsTrx === null || this.state.timelybillsTrx === undefined) {
            this.getAlertBox('NO_FILE_SELECTED', 'error');
            return;
        }
        const formData = new FormData();
        formData.append('csvFile', this.state.timelybillsTrx);
        await this.handleTimelyBillsTrxUpload(formData);
      };

    handleTimelyBillsTrxUpload = async (formData) => {
        try {
            const t=this.props.t;
            this.setState({ loading: true })
            const response = await axios.post(`${ENV.API}api/userdata/importTransactions`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            this.setState({
                loading: false,
                timelybillsTrx: null
            });
    
            if(response.data.code === 1001) {
                this.getAlertBox('FILE_UPLOAD_ERROR', 'error');
            }else if(response.data.code === 419){
                this.getFailureAlertBox('TEMPLATE_ERROR')
            }else {
                this.getSuccessAlertBox('FILE_UPLOAD_SUCCESS');      
            }
        } catch (error) {
            this.setState({ loading: false ,timelybillsTrx: null});
            this.getAlertBox('FILE_UPLOAD_ERROR', 'error');
            console.error('Upload error:', error);
        }
    };  

    handleTimelyBillsTrxFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSize = 3 * 1024 * 1024;
        if(selectedFile && selectedFile.size > maxSize) {
            this.getAlertBox('FILE_SIZE_ERROR', 'error');
        }
        this.setState({timelybillsTrx:event.target.files[0]})
    }


    getSuccessAlertBox = (message) => {
        const t=this.props.t;
        Swal.fire({
            icon: "success",
            text: t(message),
          });
    }
    getFailureAlertBox = (message) => {
        const t=this.props.t;
        Swal.fire({
            icon: "error",
            text: t(message),
          });
    }
    getAlertBox = (message, type) => {
        const t=this.props.t;
        Swal.fire({
            title:t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title:'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };
    render() {
        const {t} = this.props;
        return (
            <div style={pageStyle}>
               <Row className='mb-0 mobile-view'>
                    <Col s={12} m={12} l={12}>
                        &nbsp;
                    </Col>
                </Row>
                <Row className='mb-0 reports-section'>
                    <Col s={12} m={12} l={12} style={{position: 'relative'}} >
                        <Col s={12} m={12} l={12}  className='tab-mobile-fixed'>
                                <Tabs
                                reciveProps={true}
                                activeTab={{
                                    id: this.state.currentActiveTab
                                }}
                                className='tab-demo z-depth-1'
                                onClick={this.setActiveTab}
                            >
                                <Tabs.Tab
                                    className="tab-title"
                                    title={
                                        <div className='tab-title' title={t('IMPORT_TRANSACTIONS')}>
                                            <Trans>IMPORT_TRANSACTIONS</Trans>
                                        </div>}
                                    id="ImportTrx"
                                >
                                    <Card
                                        className="hoverable"
                                    >
                                        <div className='mb-1 padding-8'>
                                            <div className='import-trx-div'>
                                                <p className = 'font-size-16-bold'>
                                                    <Trans>TIMELYBILLS_IMPORT_NOTE</Trans>
                                                </p>
                                            </div>
                                            <div className='color-black margin-top-20 font-size-16-bold'>
                                                <Trans>TIMELYBILLS_IMPORT_STEPS_1</Trans>
                                            </div>
                                            <ol className='import-steps'>
                                                <li>
                                                    <Trans>TIMELYBILLS_IMPORT_STEPS_2</Trans> 
                                                    <a href='https://static.timelybills.app/template/TimelyBills_Transactions_Template.xltx'><Trans>DOWNLOAD_TEMPLATE</Trans></a>
                                                </li>
                                                <li>
                                                    <Trans>TIMELYBILLS_IMPORT_STEPS_3</Trans>
                                                </li>
                                                <li>
                                                    <Trans>TIMELYBILLS_IMPORT_STEPS_4</Trans>
                                                </li>
                                            </ol>
                                        </div>

                                        <Row className='mb-1'>
                                            <Col style={textStyle}>
                                                <Trans>SELECT_FILE_TEXT</Trans>
                                            </Col>
                                            <Col>
                                            <form id="timelybills-transactions" onSubmit={this.handleTimelyBillsTrxFormSubmit}>
                                                <input
                                                type="file"
                                                id="myTbTrxfile"
                                                name="myTbTrxfile"
                                                accept=".csv, .xls, .xlsx"
                                                multiple={false}
                                                style={{
                                                    padding: '8px',
                                                    marginBottom: '8px',
                                                    marginLeft: '8px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                                onChange={this.handleTimelyBillsTrxFileChange}
                                                />
                                                <label style={{ marginLeft: '8px', fontSize: '14px', color: '#555' }}>
                                                    <Trans>MAX_SIZE_TEXT</Trans>
                                                </label>
                                                <br />
                                                <label style={{ marginLeft: '8px', fontSize: '14px', color: '#555' }}>
                                                    <Trans>MAX_TRX_TEXT</Trans>
                                                </label>
                                            </form>
                                            </Col>
                                        </Row>
                                        <Row s={12} className="center-align">
                                            <Button
                                                type="submit"
                                                form="timelybills-transactions"
                                                className="blue"
                                                waves="light"
                                                >
                                                <Trans>UPLOAD</Trans>
                                            </Button>
                                        </Row>
                                        {this.props.loading === true ?
                                                <Spinner/> : null}
                                    </Card>
                                </Tabs.Tab>
                                <Tabs.Tab
                                    className="tab-title"
                                    title={
                                        <div className='tab-title' title={t('IMPORT_TRANSACTIONS_FOR_MINT_APP')}>
                                            <Trans>IMPORT_TRANSACTIONS_FOR_MINT_APP</Trans>
                                        </div>}
                                    id="ImportMintTrx"
                                >
                                    <Card
                                        className="hoverable"
                                        //title={t('IMPORT_MINT_STATEMENT')}
                                    >
                                        <p style={textStyle}>
                                            <Trans>IMPORT_MINT_STATEMENT</Trans>
                                        </p>
                                        <Row className='mb-1'>
                                            <Col style={textStyle}>
                                                Select CSV file to Import : 
                                            </Col>
                                            <Col>
                                            <form id="mint-transactions" onSubmit={this.handleFormSubmit}>
                                                <input
                                                type="file"
                                                id="myfile"
                                                name="myfile"
                                                accept=".csv"
                                                multiple={false}
                                                style={{
                                                    padding: '8px',
                                                    marginBottom: '8px',
                                                    marginLeft: '8px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                                onChange={this.handleFileChange}
                                                />
                                                <label style={{ marginLeft: '8px', fontSize: '14px', color: '#555' }}>
                                                    <Trans>MAX_SIZE_TEXT</Trans>
                                                </label>
                                            </form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Trans>IMPORT_HELP</Trans> <a href="https://support.timelybills.app/en/support/solutions/articles/84000391100-mint-to-timelybills-migration" target="_blank" style={{fontWeight: 'bold'}}>here</a>
                                        </Row>
                                        <Row s={12} className="center-align">
                                            <Button
                                                type="submit"
                                                form="mint-transactions"
                                                className="blue"
                                                waves="light"
                                                >
                                                <Trans>SUBMIT</Trans>
                                            </Button>
                                        </Row>
                                        {this.props.loading === true ?
                                                <Spinner/> : null}
                                    </Card>
                                </Tabs.Tab>
                            </Tabs>
                            </Col>
                    </Col>
                </Row>
            </div>
    )}
}

const mapStateToProps = (state) => {
    // console.log('We have data..', state);
    return state;
};
 
export default withTranslation()(connect(
    mapStateToProps,
    {
        // getIncomeExpenseTrend,
        // getTrendByCategory,
        // sendStatement
    })(withRouter(ImportData)));

