import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import {Provider} from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { Routes } from "./components/routes";
import i18n from './local/i18n';
import { Helmet } from 'react-helmet';
import {withTranslation} from 'react-i18next';
import './stylesheets/Responsive.css';
import {
    getUserAccountList,
    getRefreshedGroupUsersDetails,
    logoutUser,
    setCurrentUser
} from "./actions/authActions";
import { history } from './_helpers';
import setAxiosLanguage from "./utils/setAxiosLanguage";


// Check for token
if(localStorage.jwtToken){
    // Set auth token header auth
    const userSettings = JSON.parse(localStorage.getItem('profileDetails')) || {};
    setAuthToken(localStorage.jwtToken, userSettings.languageCode);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    // getUserAccountList
    getUserAccountList(store.dispatch);

    //get multiuser detail
    getRefreshedGroupUsersDetails(store.dispatch);


    // Check for expired tokens
    const currentTime = Date.now() / 1000;

    if(decoded.exp < currentTime ){
        // Logout User
        store.dispatch(logoutUser);

        // Redirect to Login
        // window.location.href = '/signin'
        history.push({
            pathname: '/signin'
        });
    }

}else {
    setAxiosLanguage();
}


class App extends Component {
    constructor(props) {
        super(props);
        i18n.changeLanguage(window.navigator.language);
    }

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <div className="App">
                        <Helmet>
                            <meta http-equiv="X-Content-Type-Options" content="nosniff" />
                            <meta http-equiv="X-Frame-Options" content="DENY" />
                            <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
                        </Helmet>
                        <Routes/>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default withTranslation()(App);
